var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hk-card",class:[
		_vm.noMargin ? 'no-margin' : '',
		_vm.small ? 'small' : '',
		_vm.banner ? 'banner' : '',
		_vm.bannerSmall ? 'banner-small' : ''
	],style:([
		_vm.width ? { 'width': `${_vm.width}px` } : '',
		_vm.maxWidth ? { 'max-width': `${_vm.maxWidth}px` } : '',
		_vm.minWidth ? { 'min-width': `${_vm.minWidth}px` } : '',
	]),on:{"click":_vm.emit}},[_vm._t("image"),_vm._t("header",function(){return [(_vm.header)?_c('div',{staticClass:"card-header"},[_vm._v("\n\t\t\t"+_vm._s(_vm.header)+"\n\t\t")]):_vm._e()]}),(!_vm.hasBody && _vm.body)?_c('div',{staticClass:"card-body"},[_vm._v("\n\t\t"+_vm._s(_vm.body)+"\n\t")]):_vm._e(),_vm._t("default"),_vm._t("footer",function(){return [(_vm.footer)?_c('div',{staticClass:"card-footer"},[_vm._v("\n\t\t\t"+_vm._s(_vm.footer)+"\n\t\t")]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }